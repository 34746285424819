import React,{Fragment} from 'react';
import {Link} from "react-router-dom";
// Logo

const Logo = ({sideHeader}) => {
    return (
        <Fragment>
            {sideHeader?(
                <Link to={`${process.env.PUBLIC_URL + '/'}`}>
                    <h1 style={{color: '#537d88'}}>Beth Kuehnert</h1>

                </Link>
            ):(
                <div className="header-logo col-lg-6 col-6 mt-40 mb-40">
                    <Link to={`${process.env.PUBLIC_URL + '/'}`}>
                        <h1 style={{color: '#537d88'}}>Beth Kuehnert</h1>
                    </Link>
                </div>
            )}
        </Fragment>
    );
};

export default Logo;
