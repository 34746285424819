import React from 'react';

const QuickContact = ({email}) => {
    return (
        <div className="contact-info">
            <h3 style={{color: '#537d88'}}>Email Me</h3>
            <ul>
                <li>
                    <span style={{color: '#537d88'}}>Email:</span>
                    <a href={`mailto:${email}`}>{email}</a>
                </li>
            </ul>
        </div>
    );
};

export default QuickContact;
