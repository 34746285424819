import React from 'react';
import NavItem from "./NavItem";

const NavBar = () => {
    return (
        <div className="header-menu d-lg-flex col-lg-4 d-none">
            <nav className="main-menu">
                <ul>
                    <li><a href="https://bkbydesign.com/kuehnertResume2023.pdf" target="_blank">Resume</a></li>
                    <NavItem/>
                </ul>
            </nav>
        </div>
    );
};

export default NavBar;
